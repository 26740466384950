.numbered-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.step {
    background-color: #6351ed;
    color: white;
    border-radius: 12px;
    font-size: 9px;
    padding: 2px 5px 0px 4.5px;
}

.horizontal-step-connector {
    width: 5%;
    border: 0;
    height: 1.2px;
    background-color: #6351ed;
}