body {
  background-color: #f5f5f5;
  margin: 0px;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
textarea {
  color: #6f53a7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

*:focus {
  outline: 0px;
}

/* @font-face {
  font-family: 'themes-icon';
  src: url('/assets/fonts/themes-icon.eot');
  src: url('/assets/fonts/themes-icon.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/themes-icon.woff') format('woff'),
    url('/assets/fonts/themes-icon.ttf') format('truetype'),
    url('/assets/fonts/themes-icon.svg#themes-icon') format('svg');
  font-weight: normal;
  font-style: normal;
} */
