.hidden-input-step2 {
    opacity: 0;
    position: absolute;
  }

  .checkmark-step2 {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid #767676;
    border-radius: 4px;
    position: relative;
  }

  .hidden-input-step2:checked ~ .checkmark-step2 {
    background-color: #c95cfc;
  }
  
  .checkmark-step2::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 6px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    top: 18%;
    left: 31%;
    transform: translate(-20%, -20%) rotateZ(40deg);
  }

  .records-table {
      width: 100%;
      margin: auto;
      margin-top: 10px;
      border: 1px solid #cacaca;
      border-radius: 8px;
      font-size: 11px;
      position: relative;
      max-height: 10rem;
      overflow-y: auto;
  }

  .records-table thead {
      background-color: #e0e0e0;
      line-height: 2rem;
  }

  .verify-btn:disabled {
    color: white;
    background-color: #adadad;
    cursor: not-allowed;
  }