@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
body {
  background-color: #f5f5f5;
  margin: 0px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
textarea {
  color: #6f53a7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

*:focus {
  outline: 0px;
}

/* @font-face {
  font-family: 'themes-icon';
  src: url('/assets/fonts/themes-icon.eot');
  src: url('/assets/fonts/themes-icon.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/themes-icon.woff') format('woff'),
    url('/assets/fonts/themes-icon.ttf') format('truetype'),
    url('/assets/fonts/themes-icon.svg#themes-icon') format('svg');
  font-weight: normal;
  font-style: normal;
} */

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-name {
  /* position: absolute; */
  /* bottom: -35px;
  left: 10px; */
  font-size: 0.85rem;
  color: #555;
}

.file-input label {
  display: block;
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  background-color: #6f53a7;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

input:hover + label,
input:focus + label {
  transform: scale(1.02);
}

input:focus + label {
  outline: 1px solid #000;
  outline: -webkit-focus-ring-color auto 2px;
}

.numbered-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.step {
    background-color: #6351ed;
    color: white;
    border-radius: 12px;
    font-size: 9px;
    padding: 2px 5px 0px 4.5px;
}

.horizontal-step-connector {
    width: 5%;
    border: 0;
    height: 1.2px;
    background-color: #6351ed;
}
.connect-btn-sendgrid:disabled {
    background-color: #adadad;
    color: white;
    cursor: not-allowed;
}
.hidden-input-step2 {
    opacity: 0;
    position: absolute;
  }

  .checkmark-step2 {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid #767676;
    border-radius: 4px;
    position: relative;
  }

  .hidden-input-step2:checked ~ .checkmark-step2 {
    background-color: #c95cfc;
  }
  
  .checkmark-step2::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 6px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    top: 18%;
    left: 31%;
    transform: translate(-20%, -20%) rotateZ(40deg);
  }

  .records-table {
      width: 100%;
      margin: auto;
      margin-top: 10px;
      border: 1px solid #cacaca;
      border-radius: 8px;
      font-size: 11px;
      position: relative;
      max-height: 10rem;
      overflow-y: auto;
  }

  .records-table thead {
      background-color: #e0e0e0;
      line-height: 2rem;
  }

  .verify-btn:disabled {
    color: white;
    background-color: #adadad;
    cursor: not-allowed;
  }
.sendgrid-link {
    text-decoration: none;
    color: #a0a0a0;
    font-size: 9px;
}

.sendgrid-link:visited {
    color: #a0a0a0;
}
.connected-delete-btn {
    text-decoration: none;
    color: #a0a0a0;
    font-size: 9px;
}

.connected-delete-btn:visited {
    color: #a0a0a0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


